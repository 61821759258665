<template>
	<div class="edit-form-container">
		<!-- :validate="" -->
		<div class="mcol-xs-auto nav-tabs-container section-row">
			<div class="main-group">
				<el-button
					v-for="tab in tabsList"
					:key="`tab_${tab.title}`"
					round
					:class="['capitalize inverted', { active: tab.prop == activeTab }]"
					type="primary"
					@click="toggleTab(tab)"
					v-text="tab.title"
				/>
			</div>
		</div>

		<el-form
			ref="itemForm"
			class="item-edit-form section-row form-type-2"
			:model="formData"
			:rules="rules"
		>
			<div
				v-show="activeTab == tabsList[0].prop"
				:key="tabsList[0].title"
				class="tab-container"
			>
				<div class="formRow mrow flex wrap">
					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="Алиас Категории" prop="alias">
							<el-input v-model="formData.alias" />
						</el-form-item>
					</div>
				</div>

				<div class="formRow mrow flex wrap">
					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="Название категории (ru)" prop="title_ru">
							<el-input v-model="formData.title_ru" />
						</el-form-item>
					</div>

					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="Назва категории (uk)" prop="title_uk">
							<el-input v-model="formData.title_uk" />
						</el-form-item>
					</div>
				</div>

				<div class="formRow mrow flex wrap">
					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="Описание категории (ru)" prop="description_ru">
							<editor
								v-model="formData.description_ru"
								:api-key="editorAPIKey"
								:init="{
									toolbar: 'numlist bullist',
									selector: 'textarea',
									plugins: 'lists link',
									language: 'ru'
								}"
								placeholder="Введите описание"
							>
							</editor>
						</el-form-item>
					</div>

					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="Опис категории (uk)" prop="description_uk">
							<editor
								v-model="formData.description_uk"
								:api-key="editorAPIKey"
								:init="{
									toolbar: 'numlist bullist',
									selector: 'textarea',
									plugins: 'lists link',
									language: 'ru'
								}"
								placeholder="Введіть опис"
							>
							</editor>
						</el-form-item>
					</div>
				</div>

				<div class="formRow mrow flex wrap">
					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="Родительская категория" prop="parent_id">
							<el-select filterable
								v-model="formData.parent_id"
								placeholder="выберите категорию"
								:disabed="!categoriesListWithoutCurrent.length"
								popper-class="product-category"
							>
								<el-option
									v-for="item in categoriesListWithoutCurrent"
									:key="'parent_id-' + item.id"
									:class="[
										{ 'has-children': item.count_children },
										{ 'has-parent': item.parent_id }
									]"
									:label="item.title_ru"
									:value="item.id"
								/>
							</el-select>
						</el-form-item>
					</div>
				</div>

				<div class="formRow mrow">
					<div class="mcol-xs-12 mcol-sm-6 relative">
						<el-form-item label="Дисконт" prop="discount_id">
							<SimpleSpinner :active="discountsLoading" />
							<el-select
								v-model="formData.discount_id"
								clearable
								placeholder="выберите дисконт"
								:disabled="!discountsList.length"
							>
								<el-option
									v-for="item in discountsList"
									:key="'discount_id-' + item.id"
									:label="item.title"
									:value="item.id"
								/>
							</el-select>
						</el-form-item>
					</div>
				</div>

				<div class="formRow mrow flex wrap">
					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="Популярная категория" prop="is_popular">
							<el-switch
								v-model="formData.is_popular"
								:active-value="1"
								:inactive-value="0"
							/>
							<!-- <el-checkbox v-model="formData.is_popular"
								:true-label="1" :false-label="0"
							>Популярная</el-checkbox> -->
						</el-form-item>
					</div>
				</div>
			</div>

			<div
				v-show="activeTab == tabsList[1].prop"
				:key="tabsList[1].title"
				class="tab-container"
			>
				<div class="formRow mrow flex wrap">
					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="SEO Название категории (ru)" prop="seo_title_ru">
							<el-input v-model="formData.seo_title_ru" />
						</el-form-item>
					</div>

					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="SEO Назва категории (uk)" prop="seo_title_uk">
							<el-input v-model="formData.seo_title_uk" />
						</el-form-item>
					</div>
				</div>

				<div class="formRow mrow flex wrap">
					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item
							label="SEO описание категории (ru)"
							prop="seo_description_ru"
						>
							<el-input
								v-model="formData.seo_description_ru"
								type="textarea"
								rows="4"
							/>
						</el-form-item>
					</div>

					<div class="mcol-xs-12 mcol-sm-6">
						<el-form-item label="SEO опис категории (uk)" prop="seo_description_uk">
							<el-input
								v-model="formData.seo_description_uk"
								type="textarea"
								rows="4"
							/>
						</el-form-item>
					</div>
				</div>
			</div>

			<div
				v-show="activeTab == tabsList[2].prop"
				:key="tabsList[2].title"
				class="tab-container"
			>
				<div class="formRow relative">
					<ImgUploadBlock
						ref="ImgUploadBlock"
						:picture="itemData && itemData.picture_file_name"
						:replace-selected-file="true"
					/>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
import 'element-ui/lib/theme-chalk/upload.css';

import { mapActions, mapState } from 'vuex';

import { editorAPIKey } from '@/constants/global';
import { required } from '@/constants/validation';
import { itemFormMixin, itemsFetchSetMixin, tabsMixin } from '@/mixins';
// import { prepareSubmitData } from "@/services/api/api_helpers";
// import { setupLabel } from '@/helpers';

export default {
	components: {
		Editor: () => import('@tinymce/tinymce-vue'),
		ImgUploadBlock: () => import('../../components/form/ImgUploadBlock.vue')
	},
	mixins: [itemFormMixin, itemsFetchSetMixin, tabsMixin],
	props: {
		itemData: {
			type: Object,
			default: () => ({})
		},
		fromAnotherInstance: Boolean
	},

	data() {
		return {
			itemId: null,

			formData: {
				alias: '',
				title_uk: '',
				title_ru: '',
				_lft: 0,
				_rgt: 0,
				parent_id: null,
				discount_id: null,
				display_order: 0,
				is_popular: 0,
				seo_title_uk: '',
				seo_title_ru: '',
				seo_description_uk: '',
				seo_description_ru: ''
				// file: {},
			}
		};
	},

	computed: {
		...mapState({
			categoriesLoading: state => state.categories.isLoading,
			discountsLoading: state => state.discounts.isLoading,
			discountsList: state => state.discounts.itemsList
			// authUser: state => state.auth.authUser
		}),

		/*discountLabelOptions: () => ({
			accessors: ['title', 'value'],
			delimeter: '-'
		}),*/

		// setupLabel: () => setupLabel,

		rules: () => ({
			// alias: required,
			title_uk: required,
			title_ru: required,
			display_order: required
			// parent_id: required,
			// discount_id: required,
			// seo_title_uk: required,
			// seo_title_ru: required,
			// seo_description_uk: required,
			// seo_description_ru: required
		}),

		editorAPIKey: () => editorAPIKey,

		tabsList: () => [
			{ title: 'Информация', prop: 'descriptionTabActive' },
			{ title: 'SEO', prop: 'seoTabActive' },
			{ title: 'Изображения', prop: 'imgTabActive' }
		],

		categoriesListWithoutCurrent() {
			return this.$store.getters['categories/categoriesListWithoutCurrent'];
		},

		// replaceSelectedFile: () => true,
		idPropName: () => 'alias',

		translitSettings: () => ({
			formFields: [{ prop: 'alias', sourceProp: 'title_ru', ifIsEmpty: true }]
		}),

		initialSetFetchSettings: () => [
			{
				action: 'fetch_categories',
				payload: { params: 
					{ max: -1, withChildren: false, /*orderByColumn: 'title_ru'*/ }
				},
				clean_up: 'set_categories'
			},
			{
				action: 'fetch_discounts',
				payload: { params: { max: -1 } },
				clean_up: 'set_discounts'
			}
		]
	},

	methods: {
		...mapActions({
			fetch_categories: 'categories/fetch_categories',
			set_categories: 'categories/set_categories',
			fetch_discounts: 'discounts/fetch_discounts',
			set_discounts: 'discounts/set_discounts'
		})
	}
};
</script>
